.degradado{
    background: linear-gradient(90deg, rgba(38,120,193,1) 0%, rgba(127,127,223,1) 44%, rgba(89,225,161,1) 100%) !important; 
    color: white;
}
.carousel-inner img{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0% !important;
    margin-top: 0 !important;
    width: 100% !important;
   }