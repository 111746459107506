.img-thumbnail:hover{
    filter: brightness(50%) !important;
}
.slick-prev:before {
    content: "<";
    color: #59E1A1;
    font-size: 40px;
  }
  
  .slick-next:before {
    content: ">";
    color: #59E1A1;
    font-size: 40px;
  }