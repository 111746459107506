@font-face{
    font-family: montserratr;
    src:url('../../assets/Fonts/Montserrat-Regular.ttf');
}
@font-face{
    font-family: montserratb;
    src:url('../../assets/Fonts/Montserrat-Bold.ttf');
}
@font-face{
    font-family: montserratm;
    src:url('../../assets/Fonts/Montserrat-Medium.ttf');
}
@font-face{
    font-family: montserratsm;
    src:url('../../assets/Fonts/Montserrat-SemiBold.ttf');
}
@font-face{
    font-family: planeto;
    src:url('../../assets/Fonts/Planeto.otf');
}
@font-face{
    font-family: eurocaps;
    src:url('../../assets/Fonts/EUROCAPS.TTF');
}
.navbar-nav{
    font-family: eurocaps;
    
}
.letraMontserrat{
    font-family: montserratr;
    text-align: justify !important;
}
.letraMontserratbold{
    font-family: montserratb;
}
.letraMontserratsemibold{
    font-family: montserratsm;
}
.letraPlaneto{
    font-family: planeto;
    color: #2678C1;
}
.letraPP{
    font-family: planeto;
    color:#0A1E30;
}
#letrafooter{
    font-family: montserratr;
}
.subtitulos{
    color:#0cae85;
}
.remarcado{
    color: #2678C1; 
}