.card{
    background-color: rgba(255, 255, 255, 0) !important;
    border: 1px solid rgba(187, 173, 173, 0.4) !important;
}
.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(22, 175, 8, 0.473) !important;
    transition: all 0.7s linear;
}
.card-body{
    padding: 2rem 0 !important;
}
.card-text{
    padding: 0.3rem 1rem;
    font-size: 1rem;
}
.overflow{
    overflow: hidden;
    align-items: center;
}
.imgCard:hover{
    transform: scale(0.8);
}
.mybtn{
    background: #59E1A1;
}

.miCard{
    height: 25rem;
}
.imgCard{
    background: none;
    height: 7rem;
    width: 7rem;
    text-align: center !important;
    transition: all 0.2s ease-in-out;
}